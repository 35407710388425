<template>
    <div>
        <search-area :title="!apiloading?number_of_properties_found:'loading...'" height="60" v-if="!apiloading">
            <template v-slot:divider>
                <v-divider class="mydivider"></v-divider>
            </template>
            <template v-slot:item>
                <v-tabs v-model="tab" grow icons-and-text  background-color="white" class="mytab" height="40" light  slider-size="0">
                    <v-tab href="#tab-1" style="text-transform: capitalize">Properties  </v-tab>
                    <v-tab href="#tab-2" style="text-transform: capitalize">
                        <!--                            <span>-->
                        <!--                                <v-icon size="7" v-if="tab==='tab-2'">mdi-circle-medium</v-icon> -->
                        <!--                            </span>-->

                        Serviced Plots</v-tab>
                </v-tabs>
            </template>
            <template v-slot:icon>
                <v-spacer></v-spacer>
                <v-btn icon @click="goToFilterPage()">
                    <v-icon>mdi-filter-variant</v-icon>
                </v-btn>

            </template>
        </search-area>
        <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1" id="tab-1">
                <v-container v-if="propertyIsEmpty">
                    <property-list-card v-for="(property,index) in currentProperties" :key="property.id" :property="property" />
                    <div style="text-align:center" v-if="showLoadMore">
                        <v-btn block style="color:#fff !important;font-size: 12px;text-transform: capitalize;margin-top:40px"  class="mb-20" elevation="0" type="submit" :loading="apiloading" :disabled="apiloading" width="120" color="secondary" @click="loadMore">Load More</v-btn>
                    </div>
                </v-container>
             <empty-list v-if="!propertyIsEmpty"/>
            </v-tab-item>
            <v-tab-item value="tab-2" id="tab-2">
                <v-container v-if="propertyIsEmpty">
                    <property-list-card v-for="(property,index) in currentProperties" :key="property.id" :property="property" />
                    <!-- <component :is="listLayout" :propertieslist="currentProperties"></component> -->
                    <div style="text-align:center" v-if="showLoadMore">
                        <v-btn block  style="color:#fff !important;font-size: 12px;text-transform: capitalize;margin-top:40px" class="mb-20" elevation="0" type="submit" :loading="apiloading" :disabled="apiloading" width="120" color="secondary"
                                @click="loadMore">Load More</v-btn>
                    </div>
                </v-container>
            </v-tab-item>
        </v-tabs-items>

    </div>
</template>
<script>

    import {loadingMixin} from "../../../mixins/Loading";

    export default {
        inject:["Bus"],
        mixins:[loadingMixin],

        data() {
            return {
                tab:null,
                loading:false,
                type:'',
                pageTitle:'Properties',
                properties:[],
                nextUrl:0,
                showLoadMore:false,
                path:'',
                selected_capital:'',
                propertyIsEmpty:true,
                properties_found:0

            }
        },
        computed: {
            number_of_properties_found(){
                return this.properties_found + ' Properties Found'
            },
            list_type(){
                return this.$store.getters.list_type;
            },
            // nextUrlLink(){
            //     let path = this.$store.state.paginated_module.path;
            //     let page = this.$store.state.paginated_module.next;
            //     return path+"?page="+page
            // },
            currentProperties(){
                let p = '';
                let type = this.$store.state.property_module.list_type;
                switch(type){
                    case'all':
                        this.changeTitle('Properties')
                        p = this.$store.state.property_module.all;
                        break;
                    case 'latest':
                        this.changeTitle('Latest Properties')
                        p = this.$store.state.property_module.latest_properties;
                        break;
                    case 'recommended':
                        this.changeTitle('Recommended Properties')
                        p = this.$store.state.property_module.recommended_properties;
                        break;
                    case 'hot':
                        this.changeTitle('Hot Properties')
                        p = this.$store.state.property_module.hot_properties;
                        break;
                    case 'capital':
                        this.changeTitle(this.$store.getters.selected_state+" Properties")
                        p = this.$store.state.property_module.properties_by_state;
                        break;
                    case 'search':
                        this.changeTitle("Result");
                        p = this.$store.state.property_module.property_search_result;
                        break;
                    default:
                        this.changeTitle('Properties')
                        p = this.$store.state.property_module.all;
                        break;
                }
                if(p.total > 10 ){
                    this.showLoadMore = true
                }
                // this.nextUrlAction(p)
                this.properties = p.data;
                if(p.total <= 0){this.propertyIsEmpty = true}
                // this.propertyIsEmpty = this.checkArrayLength(this.properties);


                this.path = p.path;
                this.properties_found = p.total
                return this.properties
            }

        },
        watch:{
            // '$route'(to, from){
            //     this.type = to.params.type;
            // },


        },
        methods: {

            goBack(){
                this.$router.go(-1);
            },
            showPropertyList(){

                this.$store.dispatch("listPropertiesAction",{type:this.list_type,option:'paginated'});
            },
            // nextUrlAction(t){
            //     this.$store.dispatch("nextPageAction",t);
            // },
            loadMore(){
                this.$store.dispatch("loadMoreAction",this.nextUrlLink).then((res)=>{
                    console.log('pad',res)

                    if(res.last_page === res.current_page && res.total <=10){
                        return this.showLoadMore = false;
                    }
                    res.data.forEach(data=>{
                        this.properties.push(data);
                    })
                    this.nextUrlAction({next_page_url:res.next_page_url,current_page:res.current_page});

                    return this.showLoadMore = true;
                })
            },
            changeTitle(title){
                if(this.$route.name ==='property_list')this.$route.meta.title = title;
            },
            goToFilterPage() {
               this.Bus.$emit("search-type",true)
                this.$router.push({name:'search'});
            },




        },
        created() {


            this.showPropertyList();
        },
    }
</script>
<style scoped>
    .top_title{
        display: flex;
    }
    .back_btn{
        margin-right:15px;
    }
</style>